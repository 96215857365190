window.$ = window.jQuery = require('jquery');

function getHeaderLinksDecorPosition() {
    const activeNav = $('.nav-list .active');
    const leftOffset = activeNav.position().left + 30;
    const decorWidth = activeNav.width();
    $('.link-decor').attr('style', `transform: translateX(${leftOffset}px)`).width(`${decorWidth}`);
}

const loader = new Promise(function (res, rej) {
    setTimeout(function () {
        $('#loader').addClass('invisible')
        return res('done')
    }, 4000)
})

loader.then(function () {
    setTimeout(function () {
        $('#loader').addClass('hidden')
    }, 800)
})

function closeMobileMenu() {
    $('.header nav').attr('style', '');
    setTimeout(function () {
        $('.header').removeClass('open');
    }, 300)
}

function navChange(currentPage) {
    closeMobileMenu();
    const windowWidth = $(document).width();

    if(windowWidth >= 1024) {
        $('.info-back-btn').trigger('click');
        const prevPage = currentPage;

        if(currentPage === '#home') {
            $('footer').removeClass('bg')
        } else if(!$('footer').hasClass('bg')) {
            $('footer').addClass('bg')
        }

        if (currentPage === '#contact') {
            $('.info').addClass('hidden')
        } else {
            $('.info').removeClass('hidden')
        }

        if ($(prevPage).find('.transition-card').length) {
            setTimeout(function () {
                $(`${prevPage} .transition-card`).removeClass('end-transition selected blur hidden active').attr('style', '')
                $(`${prevPage} .hidden`).removeClass('hidden blur');
                $(`${prevPage} figure`).attr({'style': '', 'class': ''});
            }, 400)
        }

        if (currentPage !== ('#' + $('.page').not('.hide').attr('id'))) {

            if (!$('a[href="'+currentPage+'"]').closest('li').hasClass('active')) {
                const parentLiItem = $('a[href="'+currentPage+'"]').closest('li');
                $('.nav-list li').removeClass('active');
                parentLiItem.addClass('active');
                getHeaderLinksDecorPosition();
            }

            if ($('.transition-block').hasClass('disappear')) {
                $('.transition-block').attr('class', `transition-block appearance`);
                $('.page:not(".hidden")').addClass('hidden');
                setTimeout(function () {
                    $('.page:not(".hide")').addClass('hide');
                    $(currentPage).removeClass('hide hidden');
                    $('footer').removeClass('hidden');
                }, 400)
            } else {
                $('footer').addClass('hidden');
                $('.transition-block').attr('class', `transition-block disappear`);
                $('.page:not(".hide")').addClass("hidden");
                setTimeout(function () {
                    $('.page').addClass('hide');
                    $('footer').addClass('hidden');
                }, 400)

                if (currentPage !== '#home') {
                    setTimeout(function () {
                        $('.transition-block').attr('class', `transition-block appearance`);
                        setTimeout(function () {
                            $('.page:not(".hide")').addClass('hide');
                            $(currentPage).removeClass('hide hidden');
                            $('footer').removeClass('hidden');
                        }, 400)
                    }, 500)
                } else {
                    setTimeout(function () {
                        $('.page:not(".hide")').addClass('hide');
                        $(currentPage).removeClass('hide hidden');
                        $('footer').removeClass('hidden');
                    }, 400)
                }
            }
        }
    }
    else {
        $('html, body').animate({
            scrollTop: $(currentPage).offset().top
        }, 1000);
    }
}

let isHiddenHeaderLinks = false;

$(document).ready(function () {
    const anchors = ['#home', '#about', '#services', '#pricing', '#contact'];
    let currentPage = jQuery.inArray(window.location.hash, anchors) === -1 ? '#home' : window.location.hash;
    let currentScroll = 0;

    navChange(currentPage);

    $('.fake-map-wrapper').on('click', function () {
        $(this).addClass('hidden')
    })

    $('body').on('click', function (e) {
        if (!$(e.target).hasClass('fake-map')) {
            $('.fake-map-wrapper').removeClass('hidden')
        }
    })

    $('.transition-card').on('mouseenter', function () {
        if (!$(this).hasClass('selected')) {
            $(`${currentPage} .transition-card .active`).removeClass('active');
            $(`${currentPage} .transition-card`).addClass('blur');
            $(this).removeClass('blur').addClass('active');
            $(currentPage).find('.btn-holder').addClass('blur');
        }

    })

    $('.transition-card').on('mouseleave', function () {
        if (!$(this).hasClass('selected')) {
            $(`${currentPage} .transition-card`).removeClass('active blur');
            $(currentPage).find('.btn-holder').removeClass('blur');
        }
    })

    function onCardClickHandler(e) {
        e.preventDefault();
        e.stopPropagation();
        const card = $(this).closest('.transition-card');
        card.find('.img-holder:first-child').clone().appendTo('.info-card .img-holder-wrapper');
        card.find('.hidden-text').clone().appendTo('.info-card .text-holder');
        currentScroll = $(window).scrollTop();
        $('html, body').animate({
            scrollTop: 0
        }, 400);
        setTimeout(function () {
            $('.info-card' + card.attr('data-target')).addClass('active');

        }, 300)
        $('body').addClass('modal-active');
    }

    $('.transition-card .btn, figure').on('click', onCardClickHandler);

    $('.info-card .info-back-btn').on('click', function (e) {
        e.preventDefault();
        e.stopPropagation();
        $(this).closest('.info-card').removeClass('active');
        $('body').removeClass('modal-active');
        $('html, body').animate({
            scrollTop: currentScroll
        }, 400);
        $('.info-card .img-holder').remove();
        $('.info-card .text-holder').empty();
    })

    $('.page-change').on('click', function (e) {
        closeMobileMenu();
        const targetIdPage = $(this).data('target');
        if(currentPage === '#home') {
            $('footer').removeClass('bg')
        } else if(!$('footer').hasClass('bg')) {
            $('footer').addClass('bg')
        }
        $(`.nav-list a[data-target="${targetIdPage}"]`).trigger('click')
    })

    $('.nav-list .nav-link').on('click', function(e) {
        if ($(this).data('target') === window.location.hash) {
            e.preventDefault();
        } else {
            navChange($(this).data('target'));
        }
    })

    $('.js-btn-modal').on('click', function(e){
        e.preventDefault();
        closeMobileMenu();
        const modal = $(this).data('modal');
        $('#' + modal).slideDown(400);
    });

    $('.js-close-btn').on('click', function(){
        if (window.innerWidth >= 1024) {
            $('.js-modal:not(#mobile-menu)').slideUp(400);
        }
        else {
            $('.js-modal').slideUp(400);
        }
    });

    $(window).on('resize orientationchange', function () {
        if (window.innerWidth >= 1024 && isHiddenHeaderLinks) {
            getHeaderLinksDecorPosition();
            isHiddenHeaderLinks = false
        } else if (window.innerWidth < 1024 ) {
            isHiddenHeaderLinks = true;
        }
    })

    $('.services').on('click', function (e) {
        const windowWidth = $(document).width();
        const activeContent = $(this).children('.services__active-content');

        if (windowWidth >= 1024) {
            e.preventDefault();
            $(this).siblings('.services').find('.services__unactive-content').removeClass('hide');
            $(this).siblings('.services').find('.services__active-content.animate__fadeInUp').removeClass('animate__fadeInUp').addClass('animate__fadeOutDownBig');
            $(this).siblings().removeClass('hover-off');
            if(activeContent.hasClass('animate__fadeInUp')) {
                activeContent.removeClass('animate__fadeInUp').addClass('animate__fadeOutDownBig');
                $(this).css({minHeight: 'auto'})
            }
            else {
                activeContent.addClass('animate__fadeInUp').removeClass('animate__fadeOutDownBig');
                $(this).css({minHeight: activeContent.outerHeight()})
            }
            $(this).children('.services__unactive-content').toggleClass('hide');
            $(this).toggleClass('hover-off');
        }
    })

    const serviceHeight = () => {
        const windowWidth = $(document).width();
        if (windowWidth >= 1024) {
            $('.services__wrapper .services').each(function (i, service) {
                const activeBlockHeight = $(this).children('.services__active-content').outerHeight();
                if ($(this).hasClass("hover-off")) {
                    $(this).css({minHeight: activeBlockHeight});
                }
            })
        }
    }

    $(window).resize(function () {
        serviceHeight();
    });

    $('.team__leader-info-btn').on('click', function (e) {
        e.preventDefault();
        $('.team__leader-info').addClass('show');
    })

    $('.show__more').on('click', function (e) {
        e.preventDefault();
        $('.all__vacancies').addClass('show');
    })

    $('.close-btn').on('click', function (e) {
        e.preventDefault();
        $('.transition__block-left-right').removeClass('show');
    })
});

$(window).on('load', function () {
    $('.animate').addClass('animated');
    $('.transition-card').addClass('transition');
    getHeaderLinksDecorPosition();
    if (window.innerWidth < 1024) {
        isHiddenHeaderLinks = true;
    }
})
